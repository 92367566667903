@import "https://unpkg.com/open-props/easings.min.css";

:root {
    --color-text-primary: #5e5e63;
    --color-heading-primary: rgb(94, 94, 99);
    --color-card-primary: #eceff1;
    --background-color-text-primary: linear-gradient(107deg, #74747a, #323235);
    --size-description-text: 1.6rem;
    --letter-spacing-close: -0.07rem;
    --background-hover-button: rgb(246,246,246);
    --color-body: #f6f6f6;

}

html {
    font-size: 62.5%;
}

body {
    color: #323235;
    background-color: var(--color-body);
    font-family: Inter, Arial, Helvetica, sans-serif;
    line-height: 1.4;
    font-size: 1.8rem;
    transition: background-color 0.3s, color 0.3s;
    width: 100vw;
    height: 100vh;
}


*, *::after, *::before {
    box-sizing: border-box;
}

/* Typography */

::selection {
    color: #fff;
    background-color: var(--color-text-primary);
}

img {
    width: 100%;
}

h1, h2, h3 {
    color: var(--color-heading-primary);
    margin-bottom: 1rem;
    line-height: 1.1;
}

h1 {
    font-size: 5rem;
    font-weight: 700;
}

h2 {
    font-size: 2.4rem;
    font-weight: 700;
}

h3 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
}

p {
    margin-top: 0;
    color: var(--color-text-primary);
    font-size: 1.6rem;
    max-width: 500px;
}

@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }

    h1 {
        font-size: 8rem;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2.4rem;
    }
}

/* Links */

a {
    text-decoration: none;
}

/*Gradient*/

.black-gradient {
    background-image: linear-gradient(141deg, #969699, #1e1e22);
    -webkit-background-clip: text;
    letter-spacing: var(--letter-spacing-close);
    color: transparent;
}


/*Switcher of Pages*/

.switcher {
    border-radius: 60px;
    background-color: rgba(166, 166, 166, 0.05);
    padding: 0.7rem 0;
    max-width: 250px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    min-height: 60px;
}


.switcher__item {
    cursor: pointer;
}

.active {
    display: inline-block;
    background-color: #ffffff;
    padding: 0.7rem 1.2rem;
    border-radius: 60px;
    font-weight: 600;
    text-align: center;
}

.switcher--container-mini {
    transition: 0.4s;
}

.switcher--container-mini:first-of-type {
    margin-right: 1rem;
}

/* Theme switch toggle */
.theme-switch {
    display: inline-block;
    position: relative;
    width: 6rem;
    height: 3.5rem;
}

.switch__input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFE484;
    transition: 0.4s;
    border-radius: 3.4rem;
}

.slider:before {
    position: absolute;
    content: '\e81a';
    color: var(--color-card-primary);
    height: 2.6rem;
    width: 2.6rem;
    left: 4px;
    bottom: 4px;
    background-color: #FFCC33;
    transition: 0.4s;
    border-radius: 50%;
}

.switch__input:checked + .slider {
    background-color: #83a3fd;
}

.switch__input:checked + .slider:before {
    transform: translateX(26px);
    content: "\f159";
    background-color: #4479C3;
}

.dark-theme {
    background-color: #1e1e22;
}

/*Icons*/

.icon {
    border-radius: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .icon {
        width: 3.2rem;
        height: 3.2rem;
    }

}

.icon__dot {
    fill: var(--color-text-primary);
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    opacity: 0.5;
}


/*Position*/

.position {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-close);
    font-weight: 600;
    color: var(--color-text-primary);
}

.position > *:not(:last-child) {
    margin-right: 1rem;
}

/*Container*/

.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 32px;
}

/*Card*/

.card {
    padding: 2rem 0 0 2rem;
    background-color: var(--color-card-primary);
    transition: all 0.5s;
    border-radius: 2.4rem;
    max-width: 900px;
}


.card:hover {
    background-color: #f6f6f6;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    transform: scale(1.05);
}

.button {
    border: 2px solid #fff;
    background-color: #ffffff;
    border-radius: 12px;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
}

.button:hover {
    background-color: var(--background-hover-button);
    border-color: #ffffff;
}

.button__text {
    margin-right: 0.7rem;
}

.button__arrow {
    opacity: 0.8;
    transition: transform 0.3s;
}

.button:hover .button__arrow {
    transform: translateX(0.5rem);
}

@media  screen and (min-width: 768px) {
    .button__text {
        font-size: 1.8rem;
    }
}


/*Links*/

.current-link {
    font-weight: bold;
    color: #323235;
}

.links {
    display: flex;
    align-items: end;
}

.list__item_adjusted {
    transform: translateY(4px);
}

.list__item_adjusted-gmail {
    max-height: 95%;
}

.links__item {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    border-radius: 3px;
    transition: transform 0.3s;

}
.links__item:hover {
    transform: scale(1.1);
}
.image {
    max-width: 100%;
    filter: grayscale(100%);
}


/*Toggler*/

.toggler {
    height: 60px;
    width: 60px;
    border-radius: 40px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 2rem;
}

.toggler__icon {
    width: 32px;
    height: 32px;
    fill: var(--background-color-text-primary);
    opacity: 0.5;
}

.toggler:hover {
    background-color: #f8f8f8;
    transform: translate3d(0.73892px, 0.739px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.toggler .icon--close {
  display: none;
}

.toggler.expanded .icon--menu {
  display: none;
}

.toggler.expanded .icon--close {
  display: block;
}

.desktop-menu-icon {
    width: 100%;
    height: 100%;
    position: relative;
}

.inline-svg {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18%;
  right: 18%;
  }

.desktop-menu-icon .svg-menu-toggle {
  fill: var(--background-color-text-primary);
  opacity: 0.5;

.bar {
    -webkit-transform:rotate(0) translateY(0) translateX(0);
    transform:rotate(0) translateY(0) translateX(0);
    opacity:1;
    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;
    -webkit-transition: -webkit-transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
}

    .bar:nth-of-type(1) {
      -webkit-transform-origin: 20px 10px;
      transform-origin: 20px 10px;
    }

    .bar:nth-of-type(3) {
      -webkit-transform-origin: 20px 20px;
      transform-origin: 20px 20px;
    }
  }

  .desktop-menu-icon.active__menu .svg-menu-toggle {

    .bar:nth-of-type(1) {
    -webkit-transform:rotate(-45deg) translateY(0) translateX(0);
    transform:rotate(-45deg) translateY(0) translateX(0);
    }

    .bar:nth-of-type(2) {
      opacity:0;
    }

  .bar:nth-of-type(3) {
    -webkit-transform:rotate(45deg) translateY(0em) translateX(0em);
    transform:rotate(45deg) translateY(0em) translateX(0em);
  }
}

/*List*/

.list {
    padding: 0;
    margin: 0;
}

.list__item {
    list-style-type: none;
    display: flex;
    padding: 1rem 0 1rem 1rem;
    cursor: pointer;
    border-radius: 6px;
    color: var(--color-text-primary);
}

.list__item:hover {
    background-color: var(--background-hover-button);
}

.list__icon {
    margin-right: 1rem;
}

.list--menu {
    width: 100%;
}

/*Menu*/


.card--menu {
    display: none;
    margin-right: 2rem;
    border-radius: 8px;
    max-width: 27rem;
    padding: 1rem;
    width: 100%;
    transition: all, 0.5s;
    background-color: #ffffff;
    overflow: hidden;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
}


.card--menu.expanded {
    display: flex;
    transition: all, 0.5s;
}


/*Header*/

.header {
    position: fixed;
    top: 2rem;
    left: 0;
    width: 100%;
    z-index: 1;
}

.header .grid--cols-2 {
    grid-template-columns: 3fr 1fr;
}

.header .grid--cols-2 {
    grid-template-areas:
            'a a'
            'b b'
;
}

.card--menu {
    grid-area: b;
    justify-self: end;
}

@media screen and (max-width: 420px) {
    .header .container {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

@media screen and (min-width: 550px) {
    .header .grid--cols-2 {
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (min-width: 768px) {
    .header .grid--cols-2 {
        grid-template-columns: 1.4fr 1fr;
    }
}

/*Grid*/

.grid {
    display: grid;
}

@media screen and (min-width: 768px) {
    .grid--cols-2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid .switcher {
    max-height: 4.5rem;
    max-width: 250px;
    justify-self: end;
    padding: 0 1.5rem;
}

/*Tab switcher*/

.experience {
    display: none;
}



/*block banner*/

.container--banner {
    margin-top: 12rem;
    margin-bottom: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.banner__heading {
    font-size: 5rem;
    margin-bottom: 3rem;
}

.banner__location {
    text-transform: uppercase;
}

.banner__container-location {
    display: flex;
    margin-bottom: 3rem;
}

.banner__icon {
    margin-right: 1rem;
    fill: var(--color-card-primary);
    opacity: 0.8;
}

@media screen and (min-width: 768px) {
    .banner__heading {
        font-size: 7rem;
    }
}

/*block experience*/

.experience.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience__card {
    max-width: 500px;
    width: auto;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    margin-bottom: 9rem;
    padding-top: 3rem;
    padding-bottom: 3rem
}

.experience__position-title {
    font-weight: 500;
}

.experience__position {
    margin-bottom: 2rem;
}

.experience--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.experience--content .button {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.year {
    text-transform: lowercase;
    font-weight: 500;
}



@media screen and (min-width: 768px){
    .button__text {
        font-size: 1.8rem;
    }

    .experience__card {
        max-width: 80%;
        width: auto;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
}

}

@media screen and (max-width: 312px) {
    .experience__position-title {
        max-width: 70px;
    }

    .experience--content .button {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
}
}

@media screen and (min-width: 381px) and (max-width: 500px) {
    .experience__position {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 380px) {
    .experience__position {
        font-size: 1.1rem;

    }

}

@media screen and (max-width: 400px) {
    .experience__position-title--fixed {
        display: grid;
        grid-template-columns: 50% 10% 30%;

}
}

/*footer*/

.footer {
    background-color: #f1f1f1;
    padding: 5rem 0 5rem 0;


}

.footer__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.links {
    margin-bottom: 2rem;
}

.footer__copyright {
    font-size: 1.6rem;
}

/*About*/
.about {
    display: none;
}

.about.expanded {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12rem;
}


/*text link*/
.text--link  {
    border-bottom: solid 2px var(--color-text-primary);
    transition: all 0.5s;
}

.text--link:hover {
    background-color: #d2d2d2;
    border-radius: 4px;
    border: none;
    padding: 2px 2px 2px 2px;
}

.text--link:link {
    color: var(--color-text-primary);
}

.text--link:visited {
    color: var(--color-text-primary);
}

/*Images*/

.about__images {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 7rem;
    align-items: center;
}

.about__image {
    max-width: 100%;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about__images > :first-child  {
    transform: translateX(1rem);
    z-index: -1;
}

.about__images > :last-child  {
    transform: translateX(-1rem);
    z-index: -1;
}

/*Arrow*/

.arrow-left {
    fill: var(--color-text-primary);
    opacity: 0.8;
}

a:visited {
    color: var(--color-text-primary);
    opacity: 0.8;
}

.back--link {
    height: 60px;
    width: 60px;
    border-radius: 40px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 2rem;
}

.back--link:hover {
    background-color: #f8f8f8;
    transform: translate3d(0.73892px, 0.739px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

/*Certification*/

.secondary-banner {
    margin: 12rem auto;
    padding-top: 5rem;
    padding-bottom: 5rem;

    border-radius: 6px;
    }

.certifications > :nth-child(even) {
    background-color: var(--color-card-primary);
}

.certifications {
    margin-bottom: 12rem;
}

.certifications__title {
    margin: 0;
    font-size: 5rem;

}


.card--certifications {
    border-radius: 6px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #f6f6f6;
    margin-bottom: 6rem;
    padding: 2rem 2rem;
    max-width: 800px;
}

.certifications__certificate {
    font-weight: bold;
    font-size: 3.2rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
}

.certifications__text {
    margin: 0;
}

.card--certifications.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.certifications__certificate--image {
    max-width: 70px;
    filter: grayscale(0);
    margin-right: 1rem;
}

.card--product {
    padding: 2rem;
}

@media screen and (min-width: 768px) {


    .card--certifications.grid {
        grid-template-columns: 30% 1fr;
        align-items: center;
        padding: 2rem 2rem 2rem 0rem;
    }

    .certifications__certificate {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .certifications > :nth-child(2) {
        padding-left: 2rem;
        font-size: 3rem;
    }

     .certifications > :nth-child(5) {
        padding-left: 1rem;
    }

    .certifications__certificate--image-big {
        max-width: 90px;
    }

}

@media screen and (min-width: 500px) {
    .certifications__title {
    margin: 0;
    font-size: 7rem;

}
}

@media screen and (max-width: 300px) {
    .certifications__title {
    margin: 0;
    font-size: 4rem;

}
}

/*Products*/

.products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    gap: 5rem;
}

.products__title {
    text-align: center;
    margin-bottom: 2rem;
}

.body--products {
    position: relative;
}

.products__banner {
    margin-bottom: 6rem;
}

.products__text {
    margin-bottom: 3rem;
}

.footer-products {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.products .sphere-animation {
    display: flex;
    justify-content: center;
}

.products .container__sphere {
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 350px) {
    .products .container__sphere {
    width: 200px;
    height: 200px;
}
}

/*experience details*/

.experience-details__title {
    margin-bottom: 2rem;

}

.experience-details__year {
    margin-bottom: 2rem;
    opacity: 0.8;
    letter-spacing: -1px;
}

.experience-details__position {
    opacity: 0.8;
    letter-spacing: -1px;
}

.experience-details {
    margin-bottom: 3rem;
}

.experience-details  p {
    margin-bottom: 0.5rem;
}

.experience-details__heading {
    display: inline-block;
    margin-bottom: 0.5rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    opacity: 0.8;
    text-transform: uppercase;
}

.experience-details__text {
    margin-bottom: 0.5rem;
    line-height: 2;
    text-transform: capitalize;
}

.experience-details__card {
    background-color: #fff;
    padding-bottom: 2rem;
    margin-bottom: 5rem;
}

/*.experience-details__card:nth-of-type(2) {*/
/*    background-color: var(--color-card-primary);*/
/*}*/

.experience-details__image {
    margin-bottom: 5rem;
    max-width: 90%;
    height: 30vh;
    object-fit: scale-down;
    display: flex;
    margin-right: auto;
    margin-left: auto;
}

.experience-details__image.raiff {
   background-color: #ffffff;
}

.experience-details__image.pl {
   background-color: #ffffff;
    padding-left: 5rem;
    padding-right: 5rem;
}

.experience-details__image.gl {
    background-color: #F37037;
}

.experience-details__approach--text {
    line-height: 1.4;
}

.experience-details__approach {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.experience-details p {
    max-width: 100%;
}

.experience--content {
    margin-bottom: 5rem;
}

.experience-details__card {
    padding-right: 2rem;
    padding-left: 2rem;
}

.experience-details__card h3 {
    margin-top: 0;
}

.container.experience-details {
    margin-bottom: 12rem;
}

@media screen and (min-width: 600px) {
    .experience-details.grid {
    grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
}

    .experience-details.grid > :nth-child(2) {
        justify-self: center;
    }

     .experience-details.grid > :nth-child(3) {
        grid-column: 1 / 3;
    }
}

@media screen and (min-width: 800px) {
     .experience-details.grid > :nth-child(1) {
        justify-self: start;
    }

     .experience-details.grid > :nth-child(2) {
        justify-self: start;
    }

      .experience-details.grid > :nth-child(3) {
        justify-self: start;
    }
}


