@import "https://unpkg.com/open-props/easings.min.css";
:root {
  --color-text-primary: #5e5e63;
  --color-heading-primary: #5e5e63;
  --color-card-primary: #eceff1;
  --background-color-text-primary: linear-gradient(107deg, #74747a, #323235);
  --size-description-text: 1.6rem;
  --letter-spacing-close: -.07rem;
  --background-hover-button: #f6f6f6;
  --color-body: #f6f6f6;
}

html {
  font-size: 62.5%;
}

body {
  color: #323235;
  background-color: var(--color-body);
  width: 100vw;
  height: 100vh;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 1.4;
  transition: background-color .3s, color .3s;
}

*, :after, :before {
  box-sizing: border-box;
}

::selection {
  color: #fff;
  background-color: var(--color-text-primary);
}

img {
  width: 100%;
}

h1, h2, h3 {
  color: var(--color-heading-primary);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 5rem;
  font-weight: 700;
}

h2 {
  font-size: 2.4rem;
  font-weight: 700;
}

h3 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  color: var(--color-text-primary);
  max-width: 500px;
  margin-top: 0;
  font-size: 1.6rem;
}

@media screen and (width >= 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

a {
  text-decoration: none;
}

.black-gradient {
  letter-spacing: var(--letter-spacing-close);
  color: #0000;
  background-image: linear-gradient(141deg, #969699, #1e1e22);
  -webkit-background-clip: text;
}

.switcher {
  background-color: #a6a6a60d;
  border-radius: 60px;
  justify-content: space-evenly;
  align-items: center;
  max-width: 250px;
  min-height: 60px;
  padding: .7rem 0;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

.switcher__item {
  cursor: pointer;
}

.active {
  text-align: center;
  background-color: #fff;
  border-radius: 60px;
  padding: .7rem 1.2rem;
  font-weight: 600;
  display: inline-block;
}

.switcher--container-mini {
  transition: all .4s;
}

.switcher--container-mini:first-of-type {
  margin-right: 1rem;
}

.theme-switch {
  width: 6rem;
  height: 3.5rem;
  display: inline-block;
  position: relative;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ffe484;
  border-radius: 3.4rem;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  color: var(--color-card-primary);
  background-color: #fc3;
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.switch__input:checked + .slider {
  background-color: #83a3fd;
}

.switch__input:checked + .slider:before {
  content: "";
  background-color: #4479c3;
  transform: translateX(26px);
}

.dark-theme {
  background-color: #1e1e22;
}

.icon {
  object-fit: cover;
  border-radius: .8rem;
  width: 2.4rem;
  height: 2.4rem;
}

@media screen and (width >= 768px) {
  .icon {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.icon__dot {
  fill: var(--color-text-primary);
  opacity: .5;
  width: .5rem;
  height: .5rem;
  display: inline-block;
}

.position {
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-close);
  color: var(--color-text-primary);
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
}

.position > :not(:last-child) {
  margin-right: 1rem;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 32px;
}

.card {
  background-color: var(--color-card-primary);
  border-radius: 2.4rem;
  max-width: 900px;
  padding: 2rem 0 0 2rem;
  transition: all .5s;
}

.card:hover {
  cursor: pointer;
  background-color: #f6f6f6;
  transform: scale(1.05);
  box-shadow: 0 8px 24px #959da533;
}

.button {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 12px;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  transition: all .3s;
  display: flex;
}

.button:hover {
  background-color: var(--background-hover-button);
  border-color: #fff;
}

.button__text {
  margin-right: .7rem;
}

.button__arrow {
  opacity: .8;
  transition: transform .3s;
}

.button:hover .button__arrow {
  transform: translateX(.5rem);
}

@media screen and (width >= 768px) {
  .button__text {
    font-size: 1.8rem;
  }
}

.current-link {
  color: #323235;
  font-weight: bold;
}

.links {
  align-items: end;
  display: flex;
}

.list__item_adjusted {
  transform: translateY(4px);
}

.list__item_adjusted-gmail {
  max-height: 95%;
}

.links__item {
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-around;
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
  transition: transform .3s;
  display: flex;
}

.links__item:hover {
  transform: scale(1.1);
}

.image {
  filter: grayscale();
  max-width: 100%;
}

.toggler {
  cursor: pointer;
  background-color: #fff;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 2rem;
  transition: all .2s;
  display: flex;
  box-shadow: 0 8px 24px #959da533;
}

.toggler__icon {
  width: 32px;
  height: 32px;
  fill: var(--background-color-text-primary);
  opacity: .5;
}

.toggler:hover {
  transform-style: preserve-3d;
  background-color: #f8f8f8;
  transform: translate3d(.73892px, .739px, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
}

.toggler .icon--close, .toggler.expanded .icon--menu {
  display: none;
}

.toggler.expanded .icon--close {
  display: block;
}

.desktop-menu-icon {
  width: 100%;
  height: 100%;
  position: relative;
}

.inline-svg {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18%;
  right: 18%;
}

.desktop-menu-icon .svg-menu-toggle {
  fill: var(--background-color-text-primary);
  opacity: .5;

  & .bar {
    opacity: 1;
    transform-origin: 20px 10px;
    -webkit-transition: -webkit-transform .4s ease-in-out, opacity .2s ease-in-out;
    transition: transform .4s ease-in-out, opacity .2s ease-in-out;
    transform: rotate(0)translateY(0)translateX(0);
  }

  & .bar:first-of-type {
    transform-origin: 20px 10px;
  }

  & .bar:nth-of-type(3) {
    transform-origin: 20px 20px;
  }
}

.desktop-menu-icon.active__menu .svg-menu-toggle {
  & .bar:first-of-type {
    transform: rotate(-45deg)translateY(0)translateX(0);
  }

  & .bar:nth-of-type(2) {
    opacity: 0;
  }

  & .bar:nth-of-type(3) {
    transform: rotate(45deg)translateY(0)translateX(0);
  }
}

.list {
  margin: 0;
  padding: 0;
}

.list__item {
  cursor: pointer;
  color: var(--color-text-primary);
  border-radius: 6px;
  padding: 1rem 0 1rem 1rem;
  list-style-type: none;
  display: flex;
}

.list__item:hover {
  background-color: var(--background-hover-button);
}

.list__icon {
  margin-right: 1rem;
}

.list--menu {
  width: 100%;
}

.card--menu {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 27rem;
  margin-right: 2rem;
  padding: 1rem;
  transition: all, all .5s;
  display: none;
  overflow: hidden;
}

.menu {
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
  display: flex;
}

.card--menu.expanded {
  transition: all, all .5s;
  display: flex;
}

.header {
  z-index: 1;
  width: 100%;
  position: fixed;
  top: 2rem;
  left: 0;
}

.header .grid--cols-2 {
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "a a"
                       "b b";
}

.card--menu {
  grid-area: b;
  justify-self: end;
}

@media screen and (width <= 420px) {
  .header .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media screen and (width >= 550px) {
  .header .grid--cols-2 {
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (width >= 768px) {
  .header .grid--cols-2 {
    grid-template-columns: 1.4fr 1fr;
  }
}

.grid {
  display: grid;
}

@media screen and (width >= 768px) {
  .grid--cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid .switcher {
  justify-self: end;
  max-width: 250px;
  max-height: 4.5rem;
  padding: 0 1.5rem;
}

.experience {
  display: none;
}

.container--banner {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 12rem;
  margin-bottom: 12rem;
  display: flex;
}

.banner__heading {
  margin-bottom: 3rem;
  font-size: 5rem;
}

.banner__location {
  text-transform: uppercase;
}

.banner__container-location {
  margin-bottom: 3rem;
  display: flex;
}

.banner__icon {
  fill: var(--color-card-primary);
  opacity: .8;
  margin-right: 1rem;
}

@media screen and (width >= 768px) {
  .banner__heading {
    font-size: 7rem;
  }
}

.experience.expanded {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.experience__card {
  width: auto;
  max-width: 500px;
  margin-bottom: 9rem;
  padding: 3rem 3.2rem;
}

.experience__position-title {
  font-weight: 500;
}

.experience__position {
  margin-bottom: 2rem;
}

.experience--content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
  display: flex;
}

.experience--content .button {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.year {
  text-transform: lowercase;
  font-weight: 500;
}

@media screen and (width >= 768px) {
  .button__text {
    font-size: 1.8rem;
  }

  .experience__card {
    width: auto;
    max-width: 80%;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}

@media screen and (width <= 312px) {
  .experience__position-title {
    max-width: 70px;
  }

  .experience--content .button {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@media screen and (width >= 381px) and (width <= 500px) {
  .experience__position {
    font-size: 1.2rem;
  }
}

@media screen and (width <= 380px) {
  .experience__position {
    font-size: 1.1rem;
  }
}

@media screen and (width <= 400px) {
  .experience__position-title--fixed {
    grid-template-columns: 50% 10% 30%;
    display: grid;
  }
}

.footer {
  background-color: #f1f1f1;
  padding: 5rem 0;
}

.footer__container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.links {
  margin-bottom: 2rem;
}

.footer__copyright {
  font-size: 1.6rem;
}

.about {
  display: none;
}

.about.expanded {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12rem;
  display: flex;
}

.text--link {
  border-bottom: solid 2px var(--color-text-primary);
  transition: all .5s;
}

.text--link:hover {
  background-color: #d2d2d2;
  border: none;
  border-radius: 4px;
  padding: 2px;
}

.text--link:link, .text--link:visited {
  color: var(--color-text-primary);
}

.about__images {
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 7rem;
}

.about__image {
  border-radius: 6px;
  max-width: 100%;
  box-shadow: 0 7px 29px #64646f33;
}

.about__images > :first-child {
  z-index: -1;
  transform: translateX(1rem);
}

.about__images > :last-child {
  z-index: -1;
  transform: translateX(-1rem);
}

.arrow-left {
  fill: var(--color-text-primary);
  opacity: .8;
}

a:visited {
  color: var(--color-text-primary);
  opacity: .8;
}

.back--link {
  cursor: pointer;
  background-color: #fff;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 2rem;
  transition: all .2s;
  display: flex;
  box-shadow: 0 8px 24px #959da533;
}

.back--link:hover {
  transform-style: preserve-3d;
  background-color: #f8f8f8;
  transform: translate3d(.73892px, .739px, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
}

.secondary-banner {
  border-radius: 6px;
  margin: 12rem auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.certifications > :nth-child(2n) {
  background-color: var(--color-card-primary);
}

.certifications {
  margin-bottom: 12rem;
}

.certifications__title {
  margin: 0;
  font-size: 5rem;
}

.card--certifications {
  background-color: #f6f6f6;
  border-radius: 6px;
  justify-content: space-around;
  align-items: center;
  max-width: 800px;
  margin: 0 auto 6rem;
  padding: 2rem;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

.certifications__certificate {
  justify-content: start;
  align-items: center;
  margin-right: 1rem;
  font-size: 3.2rem;
  font-weight: bold;
  display: flex;
}

.certifications__text {
  margin: 0;
}

.card--certifications.grid {
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  display: grid;
}

.certifications__certificate--image {
  filter: grayscale(0);
  max-width: 70px;
  margin-right: 1rem;
}

.card--product {
  padding: 2rem;
}

@media screen and (width >= 768px) {
  .card--certifications.grid {
    grid-template-columns: 30% 1fr;
    align-items: center;
    padding: 2rem 2rem 2rem 0;
  }

  .certifications__certificate {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .certifications > :nth-child(2) {
    padding-left: 2rem;
    font-size: 3rem;
  }

  .certifications > :nth-child(5) {
    padding-left: 1rem;
  }

  .certifications__certificate--image-big {
    max-width: 90px;
  }
}

@media screen and (width >= 500px) {
  .certifications__title {
    margin: 0;
    font-size: 7rem;
  }
}

@media screen and (width <= 300px) {
  .certifications__title {
    margin: 0;
    font-size: 4rem;
  }
}

.products {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-bottom: 5rem;
  display: flex;
}

.products__title {
  text-align: center;
  margin-bottom: 2rem;
}

.body--products {
  position: relative;
}

.products__banner {
  margin-bottom: 6rem;
}

.products__text {
  margin-bottom: 3rem;
}

.footer-products {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.products .sphere-animation {
  justify-content: center;
  display: flex;
}

.products .container__sphere {
  width: 300px;
  height: 300px;
}

@media screen and (width <= 350px) {
  .products .container__sphere {
    width: 200px;
    height: 200px;
  }
}

.experience-details__title {
  margin-bottom: 2rem;
}

.experience-details__year {
  opacity: .8;
  letter-spacing: -1px;
  margin-bottom: 2rem;
}

.experience-details__position {
  opacity: .8;
  letter-spacing: -1px;
}

.experience-details {
  margin-bottom: 3rem;
}

.experience-details p {
  margin-bottom: .5rem;
}

.experience-details__heading {
  letter-spacing: .1rem;
  opacity: .8;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-weight: 600;
  display: inline-block;
}

.experience-details__text {
  text-transform: capitalize;
  margin-bottom: .5rem;
  line-height: 2;
}

.experience-details__card {
  background-color: #fff;
  margin-bottom: 5rem;
  padding-bottom: 2rem;
}

.experience-details__image {
  object-fit: scale-down;
  max-width: 90%;
  height: 30vh;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.experience-details__image.raiff {
  background-color: #fff;
}

.experience-details__image.pl {
  background-color: #fff;
  padding-left: 5rem;
  padding-right: 5rem;
}

.experience-details__image.gl {
  background-color: #f37037;
}

.experience-details__approach--text {
  line-height: 1.4;
}

.experience-details__approach {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.experience-details p {
  max-width: 100%;
}

.experience--content {
  margin-bottom: 5rem;
}

.experience-details__card {
  padding-left: 2rem;
  padding-right: 2rem;
}

.experience-details__card h3 {
  margin-top: 0;
}

.container.experience-details {
  margin-bottom: 12rem;
}

@media screen and (width >= 600px) {
  .experience-details.grid {
    grid-gap: 5rem;
    grid-template-columns: 1fr 1fr;
  }

  .experience-details.grid > :nth-child(2) {
    justify-self: center;
  }

  .experience-details.grid > :nth-child(3) {
    grid-column: 1 / 3;
  }
}

@media screen and (width >= 800px) {
  .experience-details.grid > :first-child, .experience-details.grid > :nth-child(2), .experience-details.grid > :nth-child(3) {
    justify-self: start;
  }
}
/*# sourceMappingURL=certifications.0500d994.css.map */
